<template>
  <form-wizard
    @on-complete="submitAdicionarAgendamento"
    color="#00aa9e"
    :title="null"
    :subtitle="null"
    ref="wizard"
    class="text-center"
  >
    <v-overlay v-model="carregandoDados" />
    <v-alert
      dense
      outlined
      :type="tipoAgendamento === TipoAgendamento.Novo ? 'warning' : 'info'"
      v-if="
        tipoAgendamento === TipoAgendamento.Novo ||
        tipoAgendamento === TipoAgendamento.DoPedido ||
        tipoAgendamento === TipoAgendamento.DaProgramacao
      "
    >
      {{ texto_tipo }}
    </v-alert>
    <tab-content
      title="Dados do Documento"
      v-if="!isTriagem"
      icon="ti-file"
      :before-change="() => validarCamposInformadoDocumento()"
    >
      <etapa-documento
        ref="etapaDocumento"
        :documentosList="documentosListPrimeiraEtapa"
        @insereDocumentoNosAnexos="insereDocumentoNosAnexos"
        @informacoesNotaFiscal="informacoesNotaFiscal"
        @informacoesTipoDocumento="informacoesTipoDocumento"
      ></etapa-documento>
    </tab-content>
    <tab-content
      title="Dados do Agendamento"
      icon="ti-file"
      :before-change="() => validarCamposInformado()"
    >
      <etapa-agendamento ref="etapaAgendamento"></etapa-agendamento>
    </tab-content>
    <tab-content title="Anexos" icon="ti-upload">
      <etapa-anexos
        ref="etapaAnexos"
        :documentos="documentos"
        :documentosList="documentosList"
        @setNames="setDocs"
        @remove-documento="removeDocumento"
      ></etapa-anexos>
    </tab-content>
    <tab-content
      title="Serviços"
      icon="ti-settings"
      v-if="agendamento.convenios || isTriagem || isEdicaoTriado"
      :before-change="validarServicos"
    >
      <etapa-servicos ref="etapaServicos"></etapa-servicos>
    </tab-content>

    <tab-content
      title="Classificação"
      v-if="deveClassificar"
      icon="ti-view-list-alt"
      :before-change="() => $refs.etapaClassificacao.todosForamClassificados"
    >
      <etapa-classificacao ref="etapaClassificacao" class="mb-6" />
    </tab-content>
    <tab-content
      title="Estacionamento"
      v-if="isTriagem || isEdicaoTriado"
      icon="mdi mdi-parking"
    >
      <etapa-estacionamento
        ref="etapaEstacionamento"
        @bonificaServicos="bonificaServicos"
        class="mb-6"
      />
    </tab-content>
    <tab-content title="Pesagens" icon="mdi mdi-weight" v-if="devePesar">
      <etapa-pesagem ref="etapaPesagem"></etapa-pesagem>
    </tab-content>
    <v-btn color="black" dark slot="prev">
      <i class="mdi mdi-chevron-left mr-1"></i>
      Anterior
    </v-btn>
    <v-btn color="info" slot="next">
      Próximo
      <i class="mdi mdi-chevron-right ml-1"></i>
    </v-btn>
    <v-btn color="success" slot="finish" :disabled="btnConfirm">
      Finalizar
      <i class="mdi mdi-bookmark-check ml-1"></i>
    </v-btn>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { mapActions, mapState } from 'vuex'

import TipoAgendamento from '@/utils/agendamento'

import TenantsApi from '@/services/tenants'
// import ClientesApi from '@/services/tenants/clientes'
import ContratosApi from '@/services/tenants/contratos'

import { cnpj } from 'cpf-cnpj-validator'
import { cpf } from 'cpf-cnpj-validator'

import EtapaAgendamento from '@/components/patio/NovoAgendamento/Etapas/EtapaAgendamento'
import EtapaDocumento from '@/components/patio/NovoAgendamento/Etapas/EtapaDocumento'
import EtapaServicos from '@/components/patio/NovoAgendamento/Etapas/EtapaServicos'
import EtapaAnexos from '@/components/patio/NovoAgendamento/Etapas/EtapaAnexos'
import EtapaClassificacao from '@/components/patio/NovoAgendamento/Etapas/EtapaClassificacao'
import EtapaEstacionamento from '@/components/patio/NovoAgendamento/Etapas/EtapaEstacionamento'
import EtapaPesagem from '@/components/patio/NovoAgendamento/Etapas/EtapaPesagem'
import PatioApi from '@/services/patio/index'

export default {
  provide() {
    return {
      agendamento_xml: this.agendamento_xml,
      setCallbackXml: this.setCallbackXml,
      isEdicaoTriado: this.isEdicaoTriado,
      isTriagem: this.isTriagem,
      isEdicaoAgendado: this.isEdicaoAgendado,
    }
  },
  data() {
    return {
      agendamento_xml: {},
      callback_xml: {
        info_geral: () => {},
        remetente_destinatario: () => {},
        pedidos: () => {},
        observacao: () => {},
      },
      documentosList: [],
      documentosListPrimeiraEtapa: [],
      documentos: [],
      documentosNome: [],
      btnConfirm: false,
      carregandoDados: false,
      documentoInicial: [],
    }
  },
  components: {
    FormWizard,
    TabContent,
    EtapaAgendamento,
    EtapaServicos,
    EtapaAnexos,
    EtapaDocumento,
    EtapaClassificacao,
    EtapaEstacionamento,
    EtapaPesagem,
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('agendamentos', ['agendamento', 'tipoAgendamento']),
    isTriagem() {
      return this.tipoAgendamento === TipoAgendamento.Triagem
    },
    isEdicaoTriado() {
      return this.tipoAgendamento === TipoAgendamento.EdicaoTriado
    },
    isEdicaoAgendado() {
      return this.tipoAgendamento === TipoAgendamento.EdicaoAgendado
    },
    texto_tipo() {
      if (this.tipoAgendamento === TipoAgendamento.DoPedido) {
        return 'Agendamento através de um pedido.'
      }
      if (this.agendamento.programacao_cliente) {
        return 'Agendamento através de uma programação do cliente.'
      }
      if (this.agendamento.programacao_regiao) {
        return 'Agendamento através de uma programação da região.'
      }
      if (this.agendamento.programacao_fornecedor) {
        return 'Agendamento através de uma programação do fornecedor.'
      }
      if (this.agendamento.programacao_unidade) {
        return 'Agendamento através de uma programação da unidade.'
      }
      return 'Agendamento livre (sem utilização de cotas ou pedidos).'
    },
    deveClassificar() {
      return this.agendamento.servicos_triados.some(
        (s) => s.servico.exige_classificacao
      )
    },
    devePesar() {
      return (
        this.agendamento.tipo_operacao?.descricao.toLowerCase() == 'descarga' &&
        this.agendamento.fornecedor?.config?.agendamento?.exigePesagem
      )
    },

    is_default() {
      return ['NF', 'OC', 'VR', 'TI', 'TE'].includes(
        this.agendamento.tipo_documento
      )
    },
  },
  created() {
    this.TipoAgendamento = TipoAgendamento
    this.documentos = this.agendamento.anexos
      ? this.agendamento.anexos.map((doc) => {
          return { ...doc, necessity_send: false }
        })
      : []
    this.documentosList = [...this.documentos]
  },
  methods: {
    ...mapActions('tenants', ['addEmpresa']),
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('clientes', ['adicionarCliente']),
    ...mapActions('agendamentos', [
      'updateAtributoAgendamento',
      'enviarAgendamento',
      'enviarAnexos',
    ]),
    ...mapActions('patio', ['editVeiculo']),
    bonificaServicos() {
      this.$refs['etapaServicos'].bonificaServicos()
    },
    setDocs(docs) {
      this.documentosNome = docs
      try {
        this.enviarAnexos({ agendamento: this.agendamento, anexos: docs })
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },

    async atualizaVeiculo() {
      try {
        let veiculoEdit = {}

        veiculoEdit.carroceria = this.agendamento.veiculo.carroceria.id
        veiculoEdit.cor = this.agendamento.veiculo.cor.id
        veiculoEdit.estado = this.agendamento.veiculo.estado.id
        veiculoEdit.id = this.agendamento.veiculo.id
        veiculoEdit.marca = this.agendamento.veiculo.marca.id
        veiculoEdit.modalidade = this.agendamento.veiculo.modalidade.id
        veiculoEdit.motorista = this.agendamento.motorista.user.public_id
        veiculoEdit.placa_carreta_1 = this.agendamento.veiculo.placa_carreta_1
        veiculoEdit.placa_carreta_2 = this.agendamento.veiculo.placa_carreta_2
        veiculoEdit.placa_carreta_3 = this.agendamento.veiculo.placa_carreta_3
        veiculoEdit.placa_cavalo = this.agendamento.veiculo.placa_cavalo
        veiculoEdit.proprietario = this.empresaAtual.public_id
        veiculoEdit.tara = this.agendamento.veiculo.tara
        veiculoEdit.renavam = this.agendamento.veiculo.renavam
        veiculoEdit.chassis = this.agendamento.veiculo.chassis
        veiculoEdit.manufacturing_date = this.agendamento.veiculo.manufacturing_date

        await this.editVeiculo({
          ...veiculoEdit,
          proprietario: this.empresaAtual.public_id,
        })
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },

    validaPedidos() {
      if (
        this.agendamento.pedidos.length > 0 ||
        this.agendamento.pedidos_agendados.length > 0 ||
        !this.is_default
      ) {
        return true
      } else {
        this.errorMessage('Adicione o produto!')
        return false
      }
    },

    validarServicos() {
      if (
        (!this.agendamento.servicos_triados.length ||
          this.agendamento.servicos_triados.length === 0) &&
        (this.isTriagem || this.isEdicaoTriado)
      ) {
        this.errorMessage('Adicione o serviço!')
        return false
      }

      if (
        this.$refs.etapaServicos &&
        typeof this.$refs.etapaServicos.validateTransportadora === 'function'
      ) {
        if (!this.$refs.etapaServicos.validateTransportadora()) {
          this.errorMessage(
            'Por favor, selecione uma Transportadora para todos os serviços autorizados com forma de pagamento "A prazo".'
          )
          return false
        }
      }

      return true
    },

    validarCamposInformado() {
      let validation = false

      const refToValidate = this.$refs['etapaAgendamento']

      validation = this.validaPedidos() && refToValidate.validate()

      return validation
    },

    validarCamposInformadoDocumento() {
      const refToValidate = this.$refs['etapaDocumento']
      return refToValidate.validate()
    },
    carregarDados() {
      this.carregandoDados = true
    },
    finalizarCarregamentoDados() {
      this.carregandoDados = false
    },
    async submitAdicionarAgendamento() {
      if (this.validarServicos()) {
        const _this = this
        setTimeout(async () => {
          try {
            this.carregarDados()
            await this.$refs.etapaAgendamento.sendDocs()

            const agendamento = await _this.enviarAgendamento(_this.isTriagem)

            this.updateAtributoAgendamento({
              valor: agendamento.public_id,
              nomeAtributo: 'public_id',
            })

            this.updateAtributoAgendamento({
              valor: agendamento.triado,
              nomeAtributo: 'triado',
            })

            this.updateAtributoAgendamento({
              valor: agendamento.data_cota,
              nomeAtributo: 'data_cota',
            })

            this.updateAtributoAgendamento({
              valor: agendamento.data_janela,
              nomeAtributo: 'data_janela',
            })

            this.updateAtributoAgendamento({
              valor: agendamento.data_pedido,
              nomeAtributo: 'data_pedido',
            })

            await this.$refs.etapaAnexos.sendDocs()

            if (this.isTriagem || this.isEdicaoTriado) {
              const retorno = await PatioApi.getPedidosAgendados({
                agendamento: this.agendamento.public_id,
              })

              this.updateAtributoAgendamento({
                valor: retorno,
                nomeAtributo: 'pedidos_agendados',
              })

              this.$emit('exibirComprovante')

              if (
                this.agendamento.fornecedor.public_id ==
                '11bdbc50-57c0-40db-bd96-f28a02b964b5'
              ) {
                await PatioApi.confirmaChegadaPatioTesc(
                  this.empresaAtual,
                  this.agendamento
                )
              }
            } else {
              this.agendamento.agendado_em = new Date(Date.now())
              this.$emit('exibirComprovanteAgendamento')
            }
          } catch (error) {
            console.log(error)
            if (error.response) {
              this.errorMessage(error.response.data)
              return
            }
            if (error.message) {
              this.errorMessage(error.message)
              return
            }
            this.errorMessage(error)
          } finally {
            this.finalizarCarregamentoDados()
          }
        }, 1500)
      } else {
        this.$toast.error(
          'Por favor, verifique os serviços antes de finalizar.'
        )
        this.$refs.wizard.goToTab(this.$refs.wizard.activeTabIndex)
      }
    },

    removeDocumento(doc) {
      const index = this.documentosNome.indexOf(doc.attachment_key)
      if (index >= 0) {
        delete this.documentosNome[index]
        this.documentosNome = this.documentosNome.filter(
          (documento) => documento !== null
        )
      }

      if (this.documentosNome.length > 0) {
        this.btnConfirm = false
      } else {
        this.btnConfirm = true
      }
    },

    updateAgendamentoByXml(data) {
      Object.keys(data).forEach((key) => {
        this.updateAtributoAgendamento({
          valor: data[key],
          nomeAtributo: key,
        })
      })
    },
    setCallbackXml(key, func) {
      this.callback_xml[key] = func
    },
    async informacoesNotaFiscal(notaFiscal) {
      let remetente = notaFiscal.nfeProc.NFe.infNFe.emit.CNPJ
        ? notaFiscal.nfeProc.NFe.infNFe.emit.CNPJ
        : notaFiscal.nfeProc.NFe.infNFe.emit.CPF
        ? notaFiscal.nfeProc.NFe.infNFe.emit.CPF
        : null

      let destinatario = notaFiscal.nfeProc.NFe.infNFe.dest.CNPJ
        ? notaFiscal.nfeProc.NFe.infNFe.dest.CNPJ
        : notaFiscal.nfeProc.NFe.infNFe.dest.CPF
        ? notaFiscal.nfeProc.NFe.infNFe.dest.CPF
        : null

      let objetoRemetente = notaFiscal.nfeProc.NFe.infNFe.emit
      let objetoDestinatario = notaFiscal.nfeProc.NFe.infNFe.dest

      let publicIDRemetente = await this.informacoesClientesDaNota(
        remetente,
        objetoRemetente
      )
      let publicIDDestinatario = await this.informacoesClientesDaNota(
        destinatario,
        objetoDestinatario
      )

      // let transportadora = await this.informacoesTransportadoraDaNota(
      //   notaFiscal.nfeProc.NFe.infNFe?.transp?.transporta
      // )

      let remetenteNota = notaFiscal.nfeProc.NFe.infNFe.emit
      let destinatarioNota = notaFiscal.nfeProc.NFe.infNFe.dest

      const getProd = (det, key) => {
        try {
          if (Array.isArray(det)) {
            if (det.length > 0) {
              return det[0].prod[key]
            }
          } else {
            return det.prod[key]
          }
        } catch (error) {
          console.log('error!')
        }
      }
      // const getFornecedor = (dest) => {
      //   try {
      //     if (Array.isArray(dest)) {
      //       if (dest.length > 0) {
      //         return dest[0]
      //       }
      //     } else {
      //       return dest
      //     }
      //   } catch (error) {
      //     console.log('error!')
      //   }
      // }
      let agendamento = {
        numero_documento: notaFiscal.nfeProc.NFe.infNFe.ide.nNF,
        numero_serie: notaFiscal.nfeProc.NFe.infNFe.ide.serie,
        chave_acesso: notaFiscal.nfeProc.protNFe.infProt.chNFe,
        data_emissao: notaFiscal.nfeProc.NFe.infNFe.ide.dhEmi,
        cfop: getProd(notaFiscal.nfeProc.NFe.infNFe.det, 'CFOP'),
        ncm: getProd(notaFiscal.nfeProc.NFe.infNFe.det, 'NCM'),
        observacao: notaFiscal.nfeProc.NFe.infNFe.infAdic.infCpl,
        pedido: getProd(notaFiscal.nfeProc.NFe.infNFe.det, 'qCom'),
        unidade_medida: getProd(notaFiscal.nfeProc.NFe.infNFe.det, 'uCom'),
        tipo_operacao: { id: 2, descricao: 'Descarga' },
        remetente: {
          cnpj: remetente,
          trade_name: remetenteNota.xNome,
          public_id: publicIDRemetente,
        },
        destinatario: {
          cnpj: destinatario,
          trade_name: destinatarioNota.xNome,
          public_id: publicIDDestinatario,
        },
        tipo_documento: notaFiscal.tipo_documento,
        file: notaFiscal.file,
      }
      agendamento.cliente = agendamento.destinatario
      agendamento.fornecedor = agendamento.destinatario
      if (
        !(
          agendamento.fornecedor.cnpj == '84046101000940' &&
          this.tipoAgendamento === TipoAgendamento.DaProgramacao
        )
      ) {
        switch (notaFiscal.nfeProc.NFe.infNFe.transp.modFrete) {
          case 0:
            agendamento.tipo_frete = { id: 1, descricao: 'CIF' }
            break
          case 1:
            agendamento.tipo_frete = { id: 2, descricao: 'FOB' }
            break
          case 2:
            agendamento.tipo_frete = { id: 1, descricao: 'CIF' }
            break
          case 3:
            agendamento.tipo_frete = { id: 1, descricao: 'CIF' }
            break
          case 4:
            agendamento.tipo_frete = { id: 2, descricao: 'FOB' }
            break

          default:
            agendamento.tipo_frete = { id: 1, descricao: 'CIF' }
        }
      }

      agendamento.notaFiscal = true
      agendamento.tipo_documento = 'NF'
      Object.assign(this.agendamento_xml, agendamento)
      // this.updateAgendamentoByXml(agendamento)
      this.$nextTick(() => {
        this.$refs.wizard.nextTab()
        this.callback_xml.info_geral()
        this.callback_xml.remetente_destinatario()
        this.callback_xml.pedidos()
        this.callback_xml.observacao()
      })
    },

    async informacoesTipoDocumento() {
      await this.$refs.etapaAgendamento.trocouTipoDocumento()
      this.agendamento.tipo_documento = 'OC'
      this.$refs.wizard.nextTab()
    },

    async insereDocumentoNosAnexos(doc) {
      this.documentosList = doc
    },
    // async informacoesTransportadoraDaNota(transporta, objTransporadora) {
    // },
    async informacoesClientesDaNota(cliente, objCliente) {
      try {
        var empresa = await TenantsApi.getTenantByCPFOrCNPJ({
          pesquisa: cliente,
        })
        if (empresa.length == 0) {
          empresa = await TenantsApi.getTenantByCPFOrCNPJ({
            pesquisa: cliente,
          })
        }
        if (empresa.length == 0) {
          const novaEmpresa = {
            business_name: objCliente.xNome,
            trade_name: objCliente.xNome,
            cnpj: objCliente.CNPJ ? objCliente.CNPJ : null,
            cpf: objCliente.CPF ? objCliente.CPF : null,
            phone: objCliente.phone ? objCliente.phone : '9999-9999',
            tenant_criador: this.empresaAtual.public_id,
            address: objCliente.enderDest
              ? objCliente.enderDest.xLgr
              : objCliente.enderEmit.xLgr,
            district: objCliente.enderDest
              ? objCliente.enderDest.xMun
              : objCliente.enderEmit.xMun,
            zip_code: objCliente.enderDest
              ? objCliente.enderDest.CEP
              : objCliente.enderEmit.CEP,
            city: objCliente.enderDest
              ? objCliente.enderDest.xMun
              : objCliente.enderEmit.xMun,
            state: objCliente.enderDest
              ? objCliente.enderDest.UF
              : objCliente.enderEmit.UF,
            numero: objCliente.enderDest
              ? objCliente.enderDest.nro
              : objCliente.enderEmit.nro,
          }
          let novaEmpresaCadastrada = await this.addEmpresa(novaEmpresa)
          let novoCliente = {}
          novoCliente.empresa = this.empresaAtual.public_id
          novoCliente.cliente = novaEmpresaCadastrada.public_id
          let novoClienteCadastrado = await this.adicionarCliente(novoCliente)
          return novoClienteCadastrado.public_id
        } else {
          let cadastroCliente = await ContratosApi.getClientesSimple(
            this.empresaAtual.public_id,
            {
              pesquisa: cnpj.strip(cliente),
            }
          )
          if (cadastroCliente.length == 0) {
            cadastroCliente = await ContratosApi.getClientesSimple(
              this.empresaAtual.public_id,
              {
                pesquisa: cpf.strip(cliente),
              }
            )
          }
          if (cadastroCliente.length == 0) {
            let novoCliente = {}
            novoCliente.empresa = this.empresaAtual.public_id
            novoCliente.cliente = empresa[0].public_id
            let cadastroCliente = await this.adicionarCliente(novoCliente)
            return await cadastroCliente.public_id
          }
          return await cadastroCliente[0].public_id
        }
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
